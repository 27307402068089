<template>
  <div>
    <b-modal title="보상" ref="modal_reward" :hide-footer="true">
      <b-form-group id="fieldset-1">
        <b-form-select v-model="selected1" :options="options1"></b-form-select>
        <b-form-textarea
          id="textarea"
          v-model="contents"
          placeholder="상세 사유"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-form-select v-model="selected3" :options="options3" />

        *보상은 즉시 적용됩니다.<br /><br />
        <b-button @click="close">취소</b-button>
        <b-button variant="danger" @click="submit" class="ml-2">적용</b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { getRewardType, getVoucherType } from '../../api/code'
import { setEvent } from '../../api/reward'
import { getProducts } from '../../api/voucher'

export default {
  data() {
    return {
      contents: '',
      selected1: null,
      selected2: null,
      selected3: null,
      //112
      options1: [{ value: null, text: '보상 사유 선택', disabled: true }],
      //118
      options2: [{ value: null, text: '보상 내역 선택', disabled: true }],
      options3: [{ value: null, text: '상품 선택', disabled: true }],
      VOUCHER_TYPE: [],
      REWARD_TYPE: []
    }
  },
  props: {
    user_sn: ''
  },
  methods: {
    close() {
      this.selected1 = null
      this.selected2 = null
      this.selected3 = null
      this.contents = ''
      this.count = 0
      this.$refs['modal_reward'].hide()
    },
    async submit() {
      if (this.selected1 && this.contents && this.selected3 && this.user_sn) {
        const response = await setEvent(this.selected1, this.contents, this.selected3, this.user_sn)
        console.log(`response: ${response}`)
        if (response.code === '0000') {
          alert(`보상이 적용되었습니다.`)
        } else {
          alert(response.message)
        }
        this.close()
      } else {
        alert('데이터를 모두 입력해주세요')
      }
    },
    async initProducts() {
      const resp = await getProducts()
      if (resp.code === '0000') {
        resp.data.forEach((data, idx) => {
          this.options3.push({ value: data.pr_sn, text: data.title })
        })
      } else {
        alert(`error: ${resp.message}`)
      }
    }
  },
  async created() {
    this.REWARD_TYPE = await getRewardType()
    this.VOUCHER_TYPE = await getVoucherType()

    this.REWARD_TYPE.forEach((value, index) => {
      if (index !== 0) this.options1.push({ value: value.cd_value, text: value.cd_name_kr })
    })
    this.VOUCHER_TYPE.forEach((value, index) => {
      if (index !== 0) this.options2.push({ value: value.cd_value, text: value.cd_name_kr })
    })

    this.initProducts()
  },
  mounted() {}
}
</script>
