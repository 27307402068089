<template>
  <div>
    <title-nav :title="'회원상세'" :nav="'회원관리 / 회원현황 / 회원상세'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-button
              squared
              class="mx-2 mb-2 mt-2"
              variant="primary "
              size="sm"
              v-on:click="showReward(items.user_sn)"
              >운영자보상</b-button
            >
            <b-button
              squared
              class="mx-2 mb-2 mt-2"
              variant="danger"
              size="sm"
              v-on:click="member_logout(items.user_sn)"
              >강제 로그아웃</b-button
            >
          </b-row>
          <div class="table-responsive-sm">
            <table class="table mb-2" border="1">
              <colgroup>
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <tbody>
                <tr>
                  <td class="td-title">회원번호</td>
                  <td class="td-data">{{ items.user_sn }}</td>
                  <td class="td-title">E-mail</td>
                  <td class="td-data">{{ items.email }}</td>
                </tr>
                <tr>
                  <td class="td-title">닉네임</td>
                  <td class="td-data">{{ items.user_name }}</td>
                  <td class="td-title">가입매체</td>
                  <td class="td-data">{{ items.provider_cd }}</td>
                </tr>
                <tr>
                  <td class="td-title">이름</td>
                  <td class="td-data">{{ items.real_name }}</td>
                  <td class="td-title">생년월일</td>
                  <td class="td-data">{{ items.user_birthday }} {{ items.user_gender }}</td>
                </tr>
                <tr>
                  <td class="td-title">가입일시</td>
                  <td class="td-data">{{ items.reg_dt | dateFilter }}</td>
                  <td class="td-title">최근접속일시</td>
                  <td class="td-data">{{ items.last_login_dt | dateFilter }}</td>
                </tr>
                <tr>
                  <td class="td-title">휴대폰번호</td>
                  <td class="td-data">{{ items.hpno }}</td>
                  <td class="td-title">회원상태</td>
                  <td class="td-data">{{ items.user_stat_cd }}</td>
                </tr>

                <tr>
                  <td class="td-title">추천인</td>
                  <td class="td-data">
                    <template v-if="items.recom_code"
                      ><router-link
                        :to="{ name: 'detail', params: { usersn: items.recom_sn } }"
                        variant="link"
                        >{{ items.recom_sn }}</router-link
                      >
                      / {{ items.recom_code }}</template
                    >
                    <template v-else-if="items.recom_event">{{ items.recom_event }}</template>
                    <b-button
                      v-else
                      squared
                      variant="primary "
                      size="sm"
                      v-on:click="openRecommDo(items.user_sn)"
                      >추가</b-button
                    >
                  </td>
                  <td class="td-title">내 추천코드</td>
                  <td class="td-data">
                    <b-button
                      variant="outline-primary"
                      @click="openRecommList(items.user_sn)"
                      style="line-height: 0.5"
                      title="나를 추천한 리스트"
                      >{{ items.recom_cnt }}</b-button
                    >
                    {{ items.my_code }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-body p-0">
          <b-tabs content-class="mt-3">
            <user-payment />
            <user-coupon />
            <user-study-history />
          </b-tabs>

          <RecommandUserView ref="recommandUserView" />
          <RewardView ref="rewardView" :user_sn="items.user_sn" />
          <RecommandUserAdd ref="recommandUserAdd" :user_sn="items.user_sn" @refresh="getList" />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { lougoutUser } from '@/api/user'
import UserPayment from './UserPayment'
import UserCoupon from './UserCoupon'
// import UserPoint from './UserPoint'
import UserStudyHistory from './UserStudyHistory'
import { getUserDetail } from '../../api/user'
import RecommandUserView from '../util/RecommList'
import RewardView from '../util/RewardView'
import RecommandUserAdd from '../util/RecommDo.vue'

export default {
  name: 'UserDetail',
  components: {
    UserPayment,
    UserCoupon,
    // UserPoint,
    UserStudyHistory,
    RecommandUserView,
    RecommandUserAdd,
    RewardView
  },
  computed: {
    selectPenalty_1() {
      return this.selected_1 != '' ? true : false
    }
  },
  data() {
    return {
      page: 1,
      isMore: true,
      isBusy: false,
      usersn: this.$route.params.usersn,
      selected: 'nickname',
      reason: '',
      point: '',
      items: '',
      itemsRecommend: '',
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark'
    }
  },
  watch: {
    $route() {
      this.usersn = this.$route.params.usersn
      this.getList()
    }
  },
  methods: {
    openRecommList(user_sn) {
      console.log(`user_sn:${user_sn}`)
      this.$refs['recommandUserView'].getUserList(user_sn)
    },
    async getList() {
      this.isBusy = true
      const resp = await getUserDetail(this.usersn) // this.items = await user_detail(this.usersn, this.page)
      if (resp.code === '0000') {
        this.items = resp.data
        if (!this.items) {
          alert('탈퇴회원입니다.')
          this.$router.back()
        }
      } else {
        alert(resp.code)
      }
      // if (this.items.length === 0) this.isMore = false
      this.isBusy = false
    },
    setPage(page) {
      if (page === -1 && this.isMore === false) {
        this.isMore = true
      }
      this.page = this.page + page
      this.getList()
    },
    setCondition() {
      this.page = 1
      this.getList()
    },
    async member_logout(user_sn) {
      const resp = await lougoutUser(user_sn)
      console.log(`resp:${resp}`)
      if (resp.code === '0000') {
        alert(`${this.items.nickname}(${user_sn}) 로그아웃 되었습니다.`)
      }
    },
    showReward() {
      this.$refs['rewardView'].$refs.modal_reward.show()
      // const alertId = RecommendCntAlert('header', 'messages!', items)
      // console.log(`${alertId}`)
      // document.getElementById(`${alertId}`).click()
    },
    openRecommDo() {
      this.$refs['recommandUserAdd'].$refs.modal_view.show()
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>
.table-responsive-sm table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  text-align: center;
  width: 15%;
}

.table-responsive-sm table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: center;
  padding-right: 5px;
  width: 35%;
}
</style>
