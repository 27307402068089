<template>
  <div>
    <b-modal title="추천인 리스트" ref="modal_recom_list" :hide-footer="true">
      <b-table
        striped
        hover
        bordered
        class="table-sm text-sm-center"
        :borderless="borderless"
        :dark="dark"
        :fixed="fixed"
        :no-border-collapse="noCollapse"
        :fields="recomm_fields"
        :head-variant="headVariant"
        :busy="isBusy"
        :items="recomm_user_list"
        show-empty
      >
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { getRecommUserList } from '@/api/user'
export default {
  name: 'RecommedUserList',
  data() {
    return {
      isBusy: true,
      recomm_user_list: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      recomm_fields: [
        {
          key: 'no',
          label: 'No.',
          colType: 'span'
        },
        {
          key: 'email',
          label: 'Email',
          colType: 'span'
        },
        {
          key: 'user_name',
          label: '이름',
          colType: 'span'
        }
      ]
    }
  },
  methods: {
    async getUserList(user_sn) {
      this.isBusy = true
      const response = await getRecommUserList(user_sn)
      if (response.code === '0000') {
        this.recomm_user_list = response.data
        this.$refs['modal_recom_list'].show()
      } else {
        alert(response.message)
      }
      this.isBusy = false
    }
  }
}
</script>
